import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import ReactSelect from "./common/Form/Select";
import { formatNumber } from './helpers/common'

const selectOptions = [
  { value: true, label: 'Total assets over time' },
  { value: false, label: 'New Fund Registrations' },
];

export default function Chart({
  total_asset_data = [],
  active_fund_data = [],
  funds_estimated_values,
  disableBackground,
  formatTooltip,
  formatYAxis,
  name,
  selectMaxWidth,
  theme,
  title,
  type,
}) {
  const [ showActiveData, setShowActiveData ] = useState(true);

  const toggleChart = (e) => {
    setShowActiveData(!showActiveData);
  };

  const chartOptions = {
    colors: theme === 'dark' ? ['#ffbb1c', '#1186ad'] : ['#1186ad', '#acc0c8'],
    chart: {
      backgroundColor: disableBackground && null,
      type: type || 'line',
    },
    credits: false,
    title: {
      text: title || '',
    },
    plotOptions: {
      series: {
        lineWidth: 4
      }
    },
    series: [{
      data: showActiveData ? [...(total_asset_data || [])] : [...(active_fund_data || [])],
      name,
    },{
      name: 'Estimation',
      dashStyle: 'ShortDash',
      color: '#ffbb1c',
      data: showActiveData ? [...(funds_estimated_values) || []] : [],
    }],
    legend: {enabled: false},
    tooltip: {
      formatter: function(tooltip) {
        if (formatTooltip === 'dollar') return `
          ${this.x}
          <br />
          ${showActiveData ?
            `${this.series.name}: $ ${Math.round(this.y).toLocaleString('en', { minimumFractionDigits: 0 })}`
            : `# New Funds: ${this.y}`
          }
        `
        return tooltip.defaultFormatter.call(this, tooltip)
      }
    },
    xAxis: {
      labels: {
        style: {
          "color": theme === 'dark' ? "#acc0c8" : "#02314d",
          "font-family": "Raleway, sans-serif",
          "font-size": "16px",
          "font-weight": "700",
        }
      },
      lineColor: theme === 'dark' ?  'rgba(242,244,245,.2)' : '#acc0c8',
      tickWidth: 0,
    },
    yAxis: {
      labels: {
        formatter: function() {
          if (formatYAxis === 'dollar') return `$ ${formatNumber(this.value)}`
          return this.axis.defaultLabelFormatter.call(this)
        },
        style: {
          "color": theme === 'dark' ? "#acc0c8" : "#02314d",
          "font-family": "Raleway, sans-serif",
          "font-size": "16px",
          "font-weight": "700",
        }
      },
      lineColor: theme === 'dark' ?  'rgba(242,244,245,.2)' : '#acc0c8',
      lineWidth: 1,
      gridLineColor: 'transparent',
      title: {
        text: '',
      }
    },
  };

  return (
    <>
      {(active_fund_data || []).length > 0 && <div className="flex flex-justify flex-wrap mb2 sm-mb3">
        <div className="sm-col-12 sm-mb3">
          <h1 className="fs-18 semi-bold text-porcelain">Compare Closed-End Interval Funds</h1>
          <p className="text-heather">IntervalFunds.org is the Single Source of Truth on CEIFs</p>
        </div>

        <div className="col-12" style={{ maxWidth: selectMaxWidth || 200 }}>
          <ReactSelect
            className='large-orange-select'
            options={selectOptions}
            value={selectOptions.find(option => option.value === showActiveData)}
            onChange={toggleChart}
          />
        </div>
    </div>}
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </>
  )
}
