import React from 'react';
import ReactSelect from "./common/Form/Select";

export default function FundClassFilter(props) {
  return (
    <ReactSelect
      {...props}
      value={props.value.value ? props.value : props.options.find(option => option.selected)}
      className='large-orange-select'
      onChange={e => window.location.replace(`${window.location.pathname}?class_name=${e.value}`)}
    />
  )
}