import React, { useContext, useEffect } from 'react'
import {FundContext} from "./List";
import {useSort, format_float} from "../helpers/common";
import CurrencyFormat from 'react-currency-format';

export default function Fees() {
  const funds = useContext(FundContext);
  const [state, setState, sortBy] = useSort({
    funds: []
  });

  useEffect(() => {
    setState({
      funds: funds.sort((first, second) => second.last_net_asset - first.last_net_asset)
    })
  }, [funds]);

  const sort = (name, numeric=false) => sortBy('funds', name, numeric);


  return (
    <>
      <div className="funds-sticky-bottom-table-header-filters">
        <div className="flex flex-center mxn2 py2 semi-bold text-green-vogue">
          <div onClick={() => sort("name")} className="funds-tab-3-col-1 px2">
            <p className="flex flex-center pl2">
              <span>Interval Fund</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("class_name")} className="funds-tab-2-col-4 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Class</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("category")} className="funds-tab-3-col-2 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Sector</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("redemption_fee", true)} className="funds-tab-3-col-3 px2">
            <p className="flex flex-center flex-justify-center">
              <span>
                Redemption
                <br />
                Fee
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("twelve_b_one_fee", true)} className="funds-tab-3-col-4 px2">
            <p className="flex flex-center flex-justify-center">
              <span>
                12B-1
                <br />
                Fee
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("management_fee_num")} className="funds-tab-3-col-5 px2">
            <p className="flex flex-center flex-justify-center">
              <span>
                Current Mgmt
                <br />
                Fee
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>


          <div onClick={() => sort("shareholder_servicing")} className="funds-tab-3-col-6 px2">
            <p className="flex flex-center">
              <span>Servicing Fee</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("expense_ratio_gross", true)} className="funds-tab-3-col-7 px2">
            <p className="flex flex-center">
              <span>
                Expense Ratio
                <br />
                Gross
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("expense_ratio_net", true)} className="funds-tab-3-col-8 px2">
            <p className="flex flex-center">
              <span>
                Expense Ratio
                <br />
                Net
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>
        </div>
      </div>

      {state.funds.map((fund, idx) =>
        <a key={idx} href={`/funds/${fund.id}`}>
          <div className="border border-heather-o-40 center h5 mb2 rounded semi-bold text-slate-gray">
            <div className="flex flex-center mxn2">
              <div className="funds-tab-3-col-1 p2">
                <p className="left-align pl2">{fund.name}</p>
              </div>

              <div className="funds-tab-2-col-2 p2">
                <p>{fund.class_name}</p>
              </div>

              <div className="funds-tab-3-col-3 p2">
                <span className="bg-bahama-blue h6 inline-block lh-20 px1 text-upper text-white">{fund.category}</span>
              </div>

              <div className="funds-tab-3-col-4 p2">
                <p>
                  {fund.redemption_fee && <CurrencyFormat
                    thousandSeparator={true}
                    suffix={'%'}
                    value={format_float(fund.redemption_fee)}
                    displayType={'text'}
                  />}
                </p>
              </div>

              <div className="funds-tab-3-col-5 p2">
                <p>
                  {fund.twelve_b_one_fee && <CurrencyFormat
                    thousandSeparator={true}
                    suffix={'%'}
                    value={format_float(fund.twelve_b_one_fee)}
                    displayType={'text'}
                  />}
                </p>
              </div>

              <div className="funds-tab-3-col-6 p2">
                <p>{fund.management_fee_num}</p>
              </div>

              <div className="funds-tab-3-col-7 p2">
                <p>{fund.shareholder_servicing}</p>
              </div>

              <div className="funds-tab-3-col-8 p2">
                <p>
                  {fund.expense_ratio_gross && <CurrencyFormat
                    thousandSeparator={true}
                    suffix={'%'}
                    value={format_float(fund.expense_ratio_gross)}
                    displayType={'text'}
                  />}
                </p>
              </div>

              <div className="funds-tab-3-col-9 p2">
                <p>
                  {fund.expense_ratio_net && <CurrencyFormat
                    thousandSeparator={true}
                    suffix={'%'}
                    value={format_float(fund.expense_ratio_net)}
                    displayType={'text'}
                  />}
                </p>
              </div>
            </div>
          </div>
        </a>
      )}
    </>
  )
}
