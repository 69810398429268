import React, { useRef } from 'react';
import AuthenticityToken from './common/AuthenticityToken'

export default function FileButton({ action, label, name, method, accept }) {
  const form = useRef();
  const fileInput = useRef();

  return (
    <form ref={form} action={action} method={method} enctype="multipart/form-data">
      <AuthenticityToken/>
      <input
        ref={fileInput}
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        name={name}
        onChange={() => form.current.submit()}
      />
      <a href="#" onClick={(e) => { e.preventDefault(); fileInput.current.click(); }} className='btn btn-success'>{label}</a>
    </form>
  )
}