import React from 'react'
import Select from 'react-select'

export default function ReactSelect(props) {
  const {
    className,
    options,
  } = props;


  return (
    <>
      <Select
        {...props}
        classNamePrefix={`${className} react-select`}
        options={options}
      />
    </>
  )
}
