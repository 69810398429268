import React from 'react';
import { Snackbar } from '@material-ui/core'
import ReactDOM from 'react-dom'

export function showLoading(showBoolean) {
  if(showBoolean) ReactDOM.render(<SnackComponent/>, document.getElementById('loading'))
  else ReactDOM.unmountComponentAtNode(document.getElementById('loading'))
}

function SnackComponent() {
    return (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={true}
          message='Loading'
        />
    )
}