import React, { useContext, useEffect } from 'react'
import CurrencyFormat from 'react-currency-format';
import {useSort, format_float} from "../helpers/common";


export default function Overview({ funds }) {
  const [state, setState, sortBy] = useSort({
    funds: []
  });

  useEffect(() => {
    setState({
      funds: funds.sort((first, second) => second.last_net_asset - first.last_net_asset)
    })
  }, [funds]);

  const sort = (name, numeric=false) => sortBy('funds', name, numeric);

  return (
    <>
      <div className="funds-sticky-bottom-table-header-filters">
        <div className="flex flex-center mxn2 py2 semi-bold text-green-vogue">
          <div  onClick={() => sort("name")} className="funds-tab-1-col-1 px2">
            <p className="flex flex-center pl2">
              <span>Interval Fund</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("category")} className="funds-tab-1-col-2 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Sector</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("advisor")} className="funds-tab-1-col-3 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Advisor</span>
              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("strategy")}  className="funds-tab-1-col-4 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Strategy</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("last_net_asset", true)} className="funds-tab-1-col-5 px2">
            <p className="flex flex-center">
              <span>Nav Values</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("initial_filling")} className="funds-tab-1-col-6 px2">
            <p className="flex flex-center">
              <span>
                Inception
                <br />
                Date
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("one_year_return", true)} className="funds-tab-1-col-7 px2">
            <p className="flex flex-center">
              <span>
                1-yr
                <br />
                Return (%)
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("current_yield", true)} className="funds-tab-1-col-8 px2">
            <p className="flex flex-center">
              <span>
                Current
                <br />
                Yield (%)
              </span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>
        </div>
      </div>

      {state.funds.map((fund, idx) => (
        <a key={idx} href={`/funds/${fund.slug}`}>
          <div className="border border-heather-o-40 center h5 mb2 rounded semi-bold text-slate-gray">
            <div className="flex flex-center mxn2">
              <div className="funds-tab-1-col-1 p2">
                <p className="left-align pl2">{fund.name}</p>
              </div>

              <div className="funds-tab-1-col-2 p2">
                <span className="bg-bahama-blue h6 inline-block lh-20 px1 text-upper text-white">{fund.category}</span>
              </div>

              <div className="funds-tab-1-col-3 p2">
                <p>{fund.advisor}</p>
              </div>

              <div className="funds-tab-1-col-4 p2">
                <p>{fund.strategy}</p>
              </div>

              <div className="funds-tab-1-col-5 p2">
                <p>
                  {fund.last_net_asset && <CurrencyFormat
                    thousandSeparator={true}
                    prefix={'$'}
                    value={format_float(fund.last_net_asset)}
                    displayType={'text'}
                  />}
                </p>
              </div>

              <div className="funds-tab-1-col-6 p2">
                <p>{fund.initial_filling}</p>
              </div>

              <div className="funds-tab-1-col-7 p2">
                <p>
                  {fund.one_year_return && <CurrencyFormat
                    thousandSeparator={true}
                    suffix={'%'}
                    value={format_float(fund.one_year_return)}
                    displayType={'text'}
                  />}
                </p>
              </div>

              <div className="funds-tab-1-col-8 p2">
                <p>
                  {fund.current_yield && <CurrencyFormat
                    thousandSeparator={true}
                    suffix={'%'}
                    value={format_float(fund.current_yield)}
                    displayType={'text'}
                  />}
                </p>
              </div>
            </div>
          </div>
        </a>
      ))}
    </>
  )
}
