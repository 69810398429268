import axios from 'axios';
import {showSnack} from "./flasher";
import {showLoading} from "./loading";

let loaderCount = 0;

export const CSRF_TOKEN = document.querySelector("meta[name=csrf-token]").content;


export function Request(params) {
  if (params['showLoading']) showLoadingIndicator();

  return new Promise((resolve, reject) => {
    axios({
      method: params['method'] ,
      url: `${params['url']}`,
      data: params['data'],
      headers: {'X-CSRF-Token': CSRF_TOKEN, ...params['headers']}
    }).then(response => {
      if (params['notice']) showSnack({content: response.data.message, type: 'success'});
      resolve(response.data);
      if (params['showLoading']) hideLoadingIndicator();

    }, error => {

      if (params['showLoading']) hideLoadingIndicator();
      if(!params['hideError']) showSnack({ content: error.response.data.error, type: 'error' });
      reject(error)

    })
  })
}

let showLoadingIndicator = () => {
  if (loaderCount === 0) {
    showLoading(true);
    loaderCount+=1
  } else {
    loaderCount+=1
  }
};

let hideLoadingIndicator = () => {
  if (loaderCount === 1) {
    showLoading(false);
    loaderCount = 0
  } else {
    loaderCount-=1
  }
};