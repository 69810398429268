import React, { useState } from 'react';
import Input from "./common/Form/Input";

export default function HoldingPercentages({ value }) {
  const [initValue, setInitValue] = useState(value || []);

  return (
    <>
      {initValue.map((value, idx) => (
        <div className="row mb-2" key={idx} id={`holding-${idx}`}>
          <div className="col-6">
            <Input required placeholder='Name' value={value['name']} name={`fund[holding_percentages][][name]`} className='form-control inline-block'/>
          </div>

          <div className="col-6 d-flex">
            <Input required type='number' placeholder='Percentage' value={value['percentage']} name={`fund[holding_percentages][][percentage]`} className='form-control flex-fill mr-3'/>

            <div className="btn btn-danger" onClick={() => document.getElementById(`holding-${idx}`).remove()}>Remove</div>
          </div>
        </div>
      ))}

      {initValue.length < 10 && <div className="btn btn-success" onClick={() => setInitValue([...initValue, {}])}>Add</div>}
    </>
  )
}