import React, { useState } from 'react';
import { FormControl, Select } from '@material-ui/core';
import * as changeCase from "change-case";
import {Request} from "./helpers/request";

export default function ArticleStatus({ value, options, article_id }) {
  const [ initValue, setInitValue ] = useState(value);

  const changeArticleState = (value) => {
    Request({ method: 'post', url: `/admin/articles/${article_id}/${value}`, showLoading: true, notice: true });
    setInitValue(value)
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <Select
        native
        onChange={(e) => changeArticleState(e.target.value)}
        defaultValue={initValue}
        value={initValue}
        required
      >
        {Object.keys(options).map((option, idx) => (
          <React.Fragment>
            <option key={idx} value={option}>{changeCase.capitalCase(option)}</option>
          </React.Fragment>
        ))}
      </Select>
    </FormControl>
  )
}