import React from 'react'
import TextTruncate from 'react-text-truncate'
import placeholder from 'images/placeholder.png'

export default function Article({
  article
}) {

  return (
    <div className="border-bottom border-heather-o-40 flex py2">
      <div className="flex flex-center flex-justify-center mr3 sm-mr2">
        <a href={article.url}>
          <img
            className="article-img"
            onError={(e) => {
              e.target.onerror = null
              e.target.src = placeholder
            }}
            src={article.lead_image || placeholder}
            alt={article.lead_image_alt || article.title}
          />
        </a>
      </div>

      <div>
        {article.category && <a className="bg-heather fs-10 inline-block ls-2 mb2 px1 py-3 rounded semi-bold sm-mb1 text-white">{article.category}</a>}

        <div className="bold h3 mb2 sm-h5 sm-mb1 text-green-vogue">
          <a href={article.url}>
            <TextTruncate
              line={2}
              text={article.title}
            />
          </a>
        </div>

        {article.lead_text &&
          <div className="mb2 sm-h5 sm-mb1">
            <TextTruncate
              line={3}
              text={article.lead_text}
            />
          </div>
        }

        <div className="flex flex-center">
          {article.author &&
            <div className="mr1">
              <div
                className="bg-center bg-cover bg-no-repeat circle"
                style={{
                  backgroundImage: `url("${article.author && article.author.avatar}")`,
                  height: 32,
                  width: 32,
                }}
              />
            </div>
          }

          <div className="bold h6 ls-1 text-white">
            <p className="text-green-vogue">{article.author ? article.author.name : ''}</p>
            <p className="semi-bold text-blue-chill">{article.published_at}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
