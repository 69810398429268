import React from 'react';
import { Snackbar } from '@material-ui/core'
import ReactDOM from 'react-dom'
import MuiAlert from "@material-ui/lab/Alert";

export function showSnack(props) {
  ReactDOM.render(<SnackComponent {...props}/>, document.getElementById('notices'))
}

class SnackComponent extends React.Component {
  state = {
    showMessages: true
  };

  closed = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('notices'))
  };

  render() {
    const { content, type } = this.props;
    const { showMessages } = this.state;

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showMessages}
          onClose={() => this.setState({ showMessages: false })}
          onExited={this.closed}
          autoHideDuration={2000}
        >
          <MuiAlert elevation={6} variant="filled" severity={type}>
            {content}
          </MuiAlert>
        </Snackbar>
      </React.Fragment>
    )
  }

}