import React from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'

export default function RawChart({
  data,
  disableBackground,
  formatTooltip,
  formatYAxis,
  name,
  theme,
  title,
  type,
}) {
  data.forEach(function(point) {
    point[0] = Date.parse(`${point[0]}GMT`)
  });

  const sortByProperty = (column, data, order) => {
    if (order === 'asc') return data.sort((a, b) => a[column] - b[column]);
    if (order === 'desc') return data.sort((a, b) => b[column] - a[column]);
  }

  const chartOptions = {
    colors: theme === 'dark' ? ['#ffbb1c', '#1186ad'] : ['#1186ad', '#acc0c8'],
    chart: {
      backgroundColor: disableBackground && null,
      type: type || 'line',
    },
    credits: false,
    title: {
      text: title || '',
    },
    plotOptions: {
      series: {
        lineWidth: 4
      }
    },
    series: [{
      data: sortByProperty(0, data, 'asc'),
      name: name,
    }],
    legend: {enabled: false},
    xAxis: {
      reversed: false,
      type: 'datetime',
      labels: {
        formatter: function() {
          return Highcharts.dateFormat('%Y-%m-%d', this.value);
        },
        style: {
          "color": theme === 'dark' ? "#acc0c8" : "#02314d",
          "font-family": "Raleway, sans-serif",
          "font-size": "13px",
          "font-weight": "700",
        }
      },
      lineColor: theme === 'dark' ?  'rgba(242,244,245,.2)' : '#acc0c8',
      tickWidth: 0,
      type: 'category',
    },
    rangeSelector: {
      enabled: true,
      allButtonsEnabled: true,
      inputEnabled: false,
      inputStyle: {
        color: 'white',
      },
      labelStyle: {
        color: 'white',
      },
      verticalAlign: 'top',
      x: 0,
      y: 0,
      height: 100,
      inputPosition: {
        align: 'right',
        x: 0,
        y: 0
      },
      buttonPosition: {
        align: 'left',
        x: 0,
        y: 0
      },
      buttons: [
        {
          type: 'month',
          count: 3,
          text: '3m'
        },
        {
          type: 'year',
          count: 1,
          text: '1y'
        },
        {
          type: 'year',
          count: 3,
          text: '3y'
        },
        {
          type: 'ytd',
          text: 'YTD'
        },
        {
          type: 'all',
          text: 'All'
        }
      ]
    },
    tooltip: {
      formatter: function(tooltip) {
        if (formatTooltip === 'dollar') return `${name}: $ ${this.y.toFixed(2).toLocaleString('en', { minimumFractionDigits: 0 })}`
        return tooltip.defaultFormatter.call(this, tooltip)
      }
    },
    yAxis: {
      opposite: false,
      labels: {
        formatter: function() {
          if (formatYAxis === 'dollar') return `$ ${this.value.toLocaleString('en', { minimumFractionDigits: 0 })}`
          return this.axis.defaultLabelFormatter.call(this)
        },
        style: {
          "color": theme === 'dark' ? "#acc0c8" : "#02314d",
          "font-family": "Raleway, sans-serif",
          "font-size": "16px",
          "font-weight": "700",
        }
      },
      lineColor: theme === 'dark' ?  'rgba(242,244,245,.2)' : '#acc0c8',
      lineWidth: 1,
      gridLineColor: 'transparent',
      title: {
        text: '',
      }
    },
  };
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'stockChart'}
      options={chartOptions}
    />
  )
}