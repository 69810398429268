import React, { useRef, useEffect } from 'react'
import ReactSelect from './common/Form/Select'
import {useFormState, usePrevious} from "./helpers/common";

export default function RegulatoryMaterialsFilter({ categories, params, funds }) {
  const [state, setState] = useFormState({
    category: params.category,
    fund: params.fund,
  });

  console.log(state)

  let categoryOptions = [{ label: 'All Type', value: ''}];
  categoryOptions.push(...categories.map(category => { return { value: category, label: category }}));

  let fundsOptions = [{ label: 'All Funds', value: ''}, ...funds];

  const prevState = usePrevious(state);

  const form = useRef();

  useEffect(() => {
    if (prevState && prevState !== state) form.current.submit();
  }, [state]);

  const stripHash = (string) => { return string.split('#')[0] }

  return (
    <form ref={form} action='#regulatory-anchor'>
      <div className="flex flex-wrap mxn2">
        <div className="col-5 flex flex-center px2 sm-col-12 sm-mb3">
          <p className="bold h5 mr2 text-heather">Show</p>

          <div className="flex-auto">
            <ReactSelect
              isClearable
              name='fund'
              value={state.fund ? funds.find(fund => fund.value === state.fund) : fundsOptions[0]}
              options={fundsOptions}
              onChange={(obj) => setState({ fund: obj.value })}
            />
          </div>
        </div>

        <div className="col-5 flex flex-center px2 sm-col-12">
          <p className="bold h5 mr2 text-heather">Filter By</p>

          <div className="flex-auto">
            <ReactSelect
              isClearable
              name='category'
              value={
                state.category
                  ? { value: stripHash(state.category), label: stripHash(state.category) }
                  : categoryOptions[0]
              }
              options={categoryOptions}
              onChange={(obj) => setState({ category: obj.value })}
            />
          </div>
        </div>
      </div>
    </form>
  )
}
