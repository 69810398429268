import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';

export default function CurrencyInput(props) {
  let { name, value, prefix, suffix } = props;
  const [ defaultValue, setDefaultValue ] = useState(value || "");

  return (
    <>
      <CurrencyFormat
        {...props}
        thousandSeparator={true}
        prefix={prefix}
        suffix={suffix}
        value={defaultValue}
        onValueChange={(values) => setDefaultValue(values.value)}
        name=''
      />
      <input type='hidden' name={name} value={defaultValue}/>
    </>
  )
}