import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import ReactDOM from 'react-dom'

export function showDialog(props) {
  ReactDOM.render(<DialogComponent {...props}/>, document.getElementById('dialogs'))
}

function DialogComponent({ content, actions, title }) {
  let closeModal = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById('dialogs'))
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {content}
        </DialogContent>
        <DialogActions onClick={closeModal}>
          {actions}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}