import React, { useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import TextTruncate from 'react-text-truncate'

import avatar from 'images/avatar-1.png'
import bg1 from 'images/bg-1.png'

export default function Carousel({ showThumbs, articles }) {
  const [current, setCurrent] = useState(1);
  const slider = useRef(null);

  const settings = {
    autoplay: true,
    autoplaySpeed: 10000,
    infinite: true,
    pauseOnFocus: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => { setCurrent(current + 1) },
  };

  return (
    <section className="bg-green-vogue">
      <div className="container container--main container--md-px0">
        <Slider ref={slider} {...settings}>
          {articles.map((item, index) => (
            <div className="block-important relative" key={index}>
              <div
                className="bg-center bg-cover bg-no-repeat"
                style={{
                  backgroundImage: `url('${item.lead_image || bg1}')`,
                  height: 530
                }}
              />

              <div className="absolute bg-green-vogue bottom-0 left-0 opacity-75 right-0 top-0 z1" />

              <div className="absolute bg-gradient-blue bottom-0 left-0 right-0 top-0 z1" />

              <div className="absolute center col-12 left-50p md-px3 top-50p transform-center z2" style={{ maxWidth: 800 }}>
                <h2 className="bold fs-48 ls-2 mb1 md-h2 text-white">
                  <TextTruncate
                    line={2}
                    text={item.title}
                  />
                </h2>

                <p className="h3 mb3 md-h4 text-geyser">
                  <TextTruncate
                    line={3}
                    text={item.lead_text}
                  />
                </p>

                <a className="btn btn--carousel mb3" href={item.url}>READ MORE</a>

                <div className="bold h6 ls-1 text-white">
                  {item.author && <p>{item.author.name}</p>}

                  <p className="semi-bold text-jagged-ice">{item.publish_date}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>

        {
          showThumbs
          && (
            <div className="bg-white border-bottom border-heather-o-40 pb-4 sm-hide">
              <div className="flex">
                {articles.map((item, index) => (
                  <div
                    className={`
                      ${current === index + 1 ? 'border-my-sin triangle-top-white-11' : ''}
                      border-bottom bw-4 col-3 flex py2
                    `}
                    key={index}
                  >
                    <div className="border-right border-heather-o-40 col-12 p2 relative">
                      <a
                        className="absolute bottom-0 left-0 right-0 top-0 z1"
                        href={item.url}
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   slider.current.slickGoTo(index)
                        // }}
                      />

                      <p className="bold mb1 text-green-vogue">
                        <TextTruncate
                          line={3}
                          text={item.title}
                        />
                      </p>

                      <p className="fs-11">
                        <TextTruncate
                          line={4}
                          text={item.lead_text}
                        />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )
        }
      </div>
    </section>
  )
}
