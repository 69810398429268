import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyFormat from 'react-currency-format';
import {Request} from "../helpers/request";
import Pagination from 'react-js-pagination';

export default function HistoricalData({ net_assets, fundId, totalCount }) {

  const [netAssets, setNetAssets] = useState(net_assets);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsCount, setRecordsCount ] = useState(totalCount);


  const onDateChange = (start, end) => {
    setEndDate(end);
    setStartDate(start);
    setCurrentPage(1);

    fetchRecords(start, end, 1);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);

    fetchRecords(startDate, endDate, page);
  };

  const fetchRecords = (start, end, page) => {
    let params = new URLSearchParams(window.location.search);
    let class_name = params.get("class_name");
    Request({ method: 'GET', url: `/funds/${fundId}.json?${start && end ? `net_assets_date=${start},${end}` : ''}&page=${page}${class_name ? ('&class_name='+class_name) : ''}`}).then(response => {
      setNetAssets(response.net_assets)
      setRecordsCount(response.total_count)
    });
  };

  const value_color = (v) => {
    if(v == 0 || v == null) { return 'text-slate-gray' }
    return v > 0 ? 'text-chelsea-cucumber' : 'text-thunderbird'
  }

  return (
    <>
      <div className="table-outer-container">
        <div className="table-container">
          <p className="mb1">Filter by date:</p>

          <div className="border-bottom border-heather-o-40 mb2 pb2">
            <div className="flex mxn1">
              <div className="px1">
                <DatePicker
                  className="border border-slate-gray bw-2 px2 rounded"
                  selected={startDate}
                  onChange={date => onDateChange(date, endDate)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  isClearable
                  placeholderText="Date From"
                />
              </div>

              <div className="px1">
                <DatePicker
                  className="border border-slate-gray bw-2 px2 rounded"
                  selected={endDate}
                  onChange={date => onDateChange(startDate, date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  isClearable
                  placeholderText="Date To"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-center h5 mxn2 py2 semi-bold text-slate-gray text-upper">
            <div className="px2 width-20p">
              <p className="flex flex-center px2">
                <span>Date</span>
              </p>
            </div>

            <div className="px2 width-20p">
              <p className="flex flex-center px2">
                <span>Ticker</span>
              </p>
            </div>

            <div className="px2 width-20p">
              <p className="flex flex-center px2">
                <span>Nav</span>
              </p>
            </div>

            <div className="px2 width-20p">
              <p className="flex flex-center px2">
                <span>NAV $ Change</span>
              </p>
            </div>

            <div className="px2 width-20p">
              <p className="flex flex-center px2">
                <span>NAV % Change</span>
              </p>
            </div>
          </div>


          {netAssets.length > 0 ? netAssets.map((netAsset, idx) => (
            <div className="bold border border-heather-o-40 mb2 rounded">
            <div className="flex flex-center mxn2 text-green-vogue">
              <div className="p2 width-20p">
                <p className="px2">{netAsset.as_of_date}</p>
              </div>

              <div className="p2 width-20p">
                <p className="px2">{netAsset.ticker}</p>
              </div>

              <div className="p2 width-20p">

                <p className="px2">
                  {netAsset.amount && <CurrencyFormat
                    thousandSeparator={true}
                    prefix={'$'}
                    value={netAsset.amount}
                    displayType={'text'}
                  />}
                </p>
              </div>

              <div className="p2 width-20p">
                <p className={`px2 ${value_color(netAsset.value_change)}`}>
                  {netAsset.amount && <CurrencyFormat
                    thousandSeparator={true}
                    prefix={'$'}
                    value={netAsset.value_change || 0}
                    displayType={'text'}
                  />}
                </p>
              </div>

              <div className="p2 width-20p">
                <p className={`px2 ${value_color(netAsset.percentage_change)}`}>{netAsset.percentage_change || 0}%</p>
              </div>
            </div>
          </div>)): 'no values to show'}

        </div>
      </div>

      <Pagination
        activePage={currentPage}
        firstPageText={<i className='fa fa-angle-double-left'></i>}
        itemsCountPerPage={10}
        lastPageText={<i className='fa fa-angle-double-right'></i>}
        nextPageText={<i className='fa fa-angle-right'></i>}
        onChange={onPageChange}
        pageRangeDisplayed={5}
        prevPageText={<i className='fa fa-angle-left'></i>}
        totalItemsCount={recordsCount}
      />
    </>
  )
}
