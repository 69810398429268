import { useState, useEffect, useRef } from 'react';

export const formatNumber = (n) => {
  var ranges = [
    { divider: 1e18 , suffix: 'E' },
    { divider: 1e15 , suffix: 'P' },
    { divider: 1e12 , suffix: 'T' },
    { divider: 1e9 , suffix: 'B' },
    { divider: 1e6 , suffix: 'M' },
    { divider: 1e3 , suffix: 'k' }
  ]

  for (var i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      return (Math.round(n) / ranges[i].divider).toString() + ranges[i].suffix;
    }
  }

  return n.toString()
}

export const useFormState = (init = {}) => {
  const [values, setValues] = useState(init);

  const setState = (value) => {
    setValues({ ...values, ...value })
  };

  return [ values, setState ];
};

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function alphaSort(a, b, desc = false) {
  if (a) a = a.toLowerCase(); else { a = ''}
  if (b) b = b.toLowerCase(); else { b = ''}

  if (a > b) {
    if (desc) return -1;
    return 1;
  }

  if (b > a) {
    if (desc) return 1;
    return -1;
  }

  return 0;
}

export function useSort (init) {
  const [ state, setState ] = useFormState(init);


  function sort(by, name, numeric) {
    if (numeric) {
      setState({
        [by]: [...state[by]].sort((a, b) => state[`${name}SortDesc`] ? a[name] - b[name] : b[name] - a[name]),
        [`${name}SortDesc`]: !state[`${name}SortDesc`]
      })
    } else {
      setState({
        [by]: [...state[by]].sort((a, b) => alphaSort(a[name], b[name], state[`${name}SortDesc`])),
        [`${name}SortDesc`]: !state[`${name}SortDesc`]
      })
    }
  }

  return [state, setState, sort]
}

export const format_float = (f) => {
  const float = parseFloat(f).toFixed(2)
  //parseFloat() again to cut redundant zero decimals
  return parseFloat(float)
};
