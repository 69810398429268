import React, { useState } from "react"
import { TextField, Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export default function MultiSelect({ options, name, required, value }) {
  const [ initValue, setInitValue ] = useState(value);

  return (
    <React.Fragment>
      <input value={initValue.join(',')} name={name} hidden/>
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        options={options}
        value={initValue}
        freeSolo
        onChange={(e, value) => {console.log(value); setInitValue(value)}}
        renderTags={(values, getTagProps) => (
          values.map((option, index) => (
            <Chip label={option} {...getTagProps({ index })} />
          )))
        }
        renderInput={params => {
          return (
            <TextField
              {...params}
              required={required && initValue.length === 0}
              label="Tags"
              placeholder="Type and Click 'Enter'"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          )}}
      />
    </React.Fragment>
  )
}
