import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import {showDialog} from "./common/Modal";
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    '&:hover': {
      color: 'white',
    },
  },
}));

export default function DeleteButton({title, content, url}) {
  const classes = useStyles();

  return (
    <DeleteIcon onClick={() => showDialog({
      title,
      content,
      actions: <React.Fragment>
        <Button color="primary" variant="contained">Cancel</Button>
          <Button
            href={url}
            data-method='delete'
            color="secondary"
            variant="contained"
            className={classes.root}>
            Delete
          </Button>
      </React.Fragment>
    })}/>
  )
}