import React, { useState } from 'react'
import {useFormState} from "./helpers/common";


export default function CTA({ show_cta, title, body, btn_text, btn_action }) {
  const [ showFields, setShowFields ] = useState(show_cta);
  const [state, setState] = useFormState({
    title, body, btn_text, btn_action
  });

  return (
    <>
      <div className='form-group'>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">Show CTA</span>
          </div>
          <input
            className="form-control"
            type="hidden"
            value={showFields ? 1 : 0}
            name="fund[show_cta]"
          />
          <input
            className="form-control"
            type="checkbox"
            checked={showFields}
            value={showFields ? 1 : 0}
            onChange={(e) => setShowFields(e.target.checked)}
            name="fund[show_cta]"
          />
        </div>
      </div>

      {showFields &&
        <>
          <div className='form-group'>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Title</span>
              </div>
              <input
                required
                className="form-control"
                type="text"
                name="fund[title]"
                maxLength="250"
                onChange={(e) => setState({ title: e.target.value })}
                value={state.title}
              />
            </div>
          </div>

          <div className='form-group'>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Body</span>
              </div>
              <input
                required
                className="form-control"
                type="text"
                name="fund[body]"
                maxLength="60000"
                onChange={(e) => setState({ body: e.target.value })}
                value={state.body}
              />
            </div>
          </div>

          <div className='form-group'>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Button Action</span>
              </div>
              <input
                required
                className="form-control"
                type="url"
                name="fund[btn_action]"
                maxLength="250"
                onChange={(e) => setState({ btn_action: e.target.value })}
                value={state.btn_action}
              />
            </div>
          </div>

          <div className='form-group'>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Button Text</span>
              </div>
              <input
                required
                className="form-control"
                type="text"
                maxLength="250"
                name="fund[btn_text]"
                onChange={(e) => setState({ btn_text: e.target.value })}
                value={state.btn_text}
              />
            </div>
          </div>
        </>
      }

    </>
  )
}