import React from 'react'

import avatar from 'images/bg-1.png'

export default function CommentsItem({ reply }) {
  return (
    <div className="flex mb3" style={{ paddingLeft: reply && 64 }}>
      <div className="mr2" style={{ minWidth: 48 }}>
        <img className="rounded" src={reply.user && reply.user.avatar ? reply.user.avatar : avatar} style={{ maxWidth: 48 }} />
      </div>

      <div className="flex-auto">
        <div className="border-bottom border-heather-o-40 flex flex-justify mb1 pb1">
          <div className="flex flex-center">
            <p className="h2 mr1 semi-bold text-green-vogue">{reply.user ? reply.user.name : 'Deleted User'}</p>

            {reply.author && <p className="bg-my-sin bold fs-10 lh-20 mr1 px1 rounded text-green-vogue">Author</p>}

            <p className="semi-bold text-heather">{reply.created_at}</p>
          </div>

          {/*
          <a className="bold text-heather" href="">
            Reply

            <i className="fa fa-reply ml1" />
          </a>
          */}
        </div>

        <p>
          {reply.body}
        </p>
      </div>
    </div>
  )
}
