import React, { useState } from 'react'
import CommentsItem from './CommentsItem'
import ReactSelect from './common/Form/Select'

import avatar from 'images/bg-1.png'
import {Request} from "./helpers/request";
import {useFormState} from "./helpers/common";

export default function Comments({ comments, article_id, user }) {
  const [state, setState] = useFormState({});
  const [ replies, setReplies] = useState(comments);
  const [ showComments, setShowComments] = useState(!!user && comments.length === 0);

  const commentsOptions = [
    { label: 'Date Published', value: '' },
    { label: 'option2', value: '' },
    { label: 'option3', value: '' },
  ];

  const submit = (e) => {
    e.preventDefault();
    Request({ method: 'POST', url: '/comments', data: { article_id, user_id: user.id, ...state }}).then(response => {
      setReplies([response, ...replies]);
      setState({ body: ''})
    })
  };

  return (
    <>
      {!showComments && comments.length !== 0 && <div onClick={() => setShowComments(!showComments)} className="btn btn--more text-upper cursor-pointer" href="">{comments.length ? `${showComments ? 'Hide' : 'Show'} ${comments.length} comments` : 'no comments to show'}</div>}


      {(replies.length === 0 || showComments) && <>
        <div className="border-bottom border-heather-o-40 flex flex-justify mb3 pb2 sm-block">
          <p className="box-title sm-mb3">{`There Are ${replies.length || 'no'} Insightful comments`}</p>

          {!user && <div onClick={() => window.location.replace('/linkedin/auth')} className="text-slate-gray cursor-pointer">Login to Comment</div>}
        </div>
        <div className="flex mb3">
          <div className="mr2" style={{ minWidth: 48 }}>
            <img className="rounded" src={user && user.avatar ? user.avatar : avatar} style={{ maxWidth: 48 }} />
          </div>

          <form className="flex-auto" onSubmit={submit}>
            <input
              className="border border-slate-gray bw-2 col-12 px2 rounded"
              placeholder="Type here"
              type="text"
              style={{ height: 48 }}
              value={state.body}
              onChange={(e) => setState({ body: e.target.value })}
              readOnly={!user}
              onClick={() => !user &&  window.location.replace('/linkedin/auth')}
            />
          </form>
        </div>
      {replies.map((comment, idx2) => (
        <CommentsItem key={idx2} reply={comment} />
      ))}
      </>}
    </>
  )
}
