import React, { useEffect, useRef, useState } from 'react';
import { CSRF_TOKEN, Request } from "./helpers/request";
import Medium from 'medium-editor'
import 'medium-editor/dist/css/medium-editor.css'
let $ = require('jquery');
require('medium-editor-insert-plugin')($);

export default function MediumEditor(props) {
  const inputEl = useRef(null);
  const [state, setState] = useState(props.value);

  useEffect(() => {
    const editor  = new Medium('#_medium_editor',  {
        delay: 1000,
        targetBlank: true,
        extensions: {
          'imageDragging': {},
        },
        toolbar: {
          buttons: [
            'bold',
            'italic',
            'underline',
            'anchor',
            'h2',
            'h3',
            'html',
            {
              name: 'justifyLeft',
              contentDefault: '<i class="fas fa-align-left" />',
            },
            {
              name: 'justifyCenter',
              contentDefault: '<i class="fas fa-align-center" />',
            },
            {
              name: 'justifyRight',
              contentDefault: '<i class="fas fa-align-right" />',
            },
            {
              name: 'justifyFull',
              contentDefault: '<i class="fas fa-align-justify" />',
            },
          ],
          diffLeft: 25,
          diffTop: 10,
        },
        anchor: {
          placeholderText: 'Adjon meg egy linket',
          customClassOption: 'btn',
          customClassOptionText: 'Create Button'
        },
        paste: {
          cleanPastedHTML: true,
          cleanAttrs: ['style', 'dir'],
          cleanTags: ['label', 'meta'],
          unwrapTags: ['sub', 'sup']
        },
        anchorPreview: {
          hideDelay: 300
        },
        placeholder: {
          text: 'Enter Text'
        }
      });

    $('#_medium_editor').mediumInsert({
      editor: editor,
      addons: {
        images: {
          fileUploadOptions: { url: '/images', headers: {'X-CSRF-Token': CSRF_TOKEN } },
          fileDeleteOptions: { headers: {'X-CSRF-Token': CSRF_TOKEN } },
          actions: 	{
            remove: {
              label: '<span class="fas fa-trash"></span>',
              clicked: function (elements) {
                for (let element of elements) {
                  Request({ method: 'DELETE', url: element.currentSrc});
                  element.remove();
                }
              }
            }
          },
          deleteMethod: 'DELETE',
          preview: false
        },
        embeds: {
          label: '<span class="fab fa-youtube"></span>',
          oembedProxy: 'https://medium.iframe.ly/api/oembed?iframe=1',
        }
      }
    });

  }, []);

  return (
    <textarea
      {...props}
      id='_medium_editor'
      onChange={e => setState(e.target.value)}
      value={state}
      ref={inputEl}
      className={props.class}
    />
  )
}
