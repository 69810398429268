import React, { useState, useEffect } from 'react'
import Fees from './Fees'
import Overview from './Overview'
import Performance from './Performance'
import ReactSelect from '../common/Form/Select'
import {Request} from '../helpers/request';

export const FundContext = React.createContext();

const FundsProvider = FundContext.Provider;

export default function FundsList({
  initial_funds,
  funds,
}) {
  const [currentTab, SetCurrentTab] = useState(1);
  const [category, setCategory] = useState('');
  const [stage, setStage] = useState('');
  const [strategy, setStrategy] = useState('');
  const [className, setClassName] = useState('');
  const [initialFunds, setInitialFunds] = useState(initial_funds);
  const [fundsData, setFundsData] = useState(funds);
  const [netAssetsRange, setNetAssetsRange] = useState('');

  useEffect(() => {
    Request({
      method: 'GET',
      url: `/funds.json?category=${category}&net_assets_range=${netAssetsRange}&strategy=${strategy}&stage=${stage}&class_name=${className}&initial=${currentTab === 1}`,
    }).then(response => {
      setInitialFunds(response)
      setFundsData(response)
    })
  }, [category, stage, strategy, className, currentTab, netAssetsRange]);

  const tabs = [
    { name: 'Overview' },
    { name: 'Performance' },
    { name: 'Fees' },
  ];

  const { categories, stages, strategies, classes } = funds.reduce((result, fund) => {
    if(fund.category)   result.categories.add(fund.category)
    if(fund.stage)      result.stages.add(fund.stage)
    if(fund.strategy)   result.strategies.add(fund.strategy)
    if(fund.class_name) result.classes.add(fund.class_name)
    return result
  }, {categories: new Set(), stages: new Set(), strategies: new Set(), classes: new Set() })

  const allCategories = [
    { value: '', label: 'All Categories' },
    ...[...categories].map(category => ({ label: category, value: category }))
  ];

  const allStages = [
    { value: '', label: 'All Stages' },
    ...[...stages].map(stage => ({ label: stage, value: stage }))
  ];

  const allStrategies = [
    { value: '', label: 'All Strategies' },
    ...[...strategies].map(strategy => ({ label: strategy, value: strategy }))
  ];

  const allClasses = [
    { value: '', label: 'All Classes' },
    ...[...classes].map(class_name => ({ label: class_name, value: class_name }))
  ];

  const TotalAssests = [
    { value: '', label: 'Total Assets' },
    { value: '0,5000000', label: '0-5M' },
    { value: '5000000,10000000', label: '5-10M' },
    { value: '10000000,100000000', label: '10-100M' },
    { value: '100000000,1000000000', label: '100-1B' },
    { value: '1000000000,2000000000', label: '1B-2B' },
    { value: '2000000000,infinity', label: '2B+' },

  ];
  return (
    <>
      <div className="bg-white" style={{ marginTop: '-28px' }}>
        <div className="funds-sticky-top-table-header bg-porcelain bold flex flex-wrap fs-18 sm-h4 text-upper">
          {tabs.map((tab, index) => (
            <a
              className={`
                ${currentTab === index + 1 ? 'bg-white text-green-vogue' : 'text-heather'}
                px3 py2 sm-px2
              `}
              href=""
              key={tab.name}
              onClick={(e) => { e.preventDefault(); SetCurrentTab(index + 1) }}
            >
              {tab.name}
            </a>
          ))}
        </div>

        <div className="table-outer-container">
          <div className="table-container">
            <div className="funds-sticky-bottom-table-header">
              <div className="border-bottom border-heather-o-40 pt3">
                <div className="flex mxn2">
                  <div className="px2 width-20p">
                    <ReactSelect defaultValue={allCategories[0]} options={allCategories} onChange={(category) => setCategory(category.value)} />
                  </div>

                  <div className="px2 width-20p">
                    <ReactSelect defaultValue={allStrategies[0]} options={allStrategies} onChange={(strategy) => setStrategy(strategy.value)} />
                  </div>

                  <div className="px2 width-20p">
                    <ReactSelect defaultValue={allStages[0]} options={allStages} onChange={(stage) => setStage(stage.value)} />
                  </div>

                  <div className="px2 width-20p">
                    <ReactSelect defaultValue={TotalAssests[0]} options={TotalAssests} onChange={(net_assets_range) => setNetAssetsRange(net_assets_range.value)} />
                  </div>

                  <div className="px2 width-20p">
                    <ReactSelect defaultValue={allClasses[0]} options={allClasses} onChange={(className) => setClassName(className.value)} />
                  </div>
                </div>
              </div>
            </div>
            {currentTab === 1 && <Overview funds={initialFunds} />}
            <FundsProvider value={fundsData}>
              {currentTab === 2 && <Performance />}
              {currentTab === 3 && <Fees />}
            </FundsProvider>
          </div>
        </div>
      </div>
    </>
  )
}
