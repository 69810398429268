import React from 'react';

export default function Input(props) {
  const [value, setValue] = React.useState(props.value);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  )
}