import React, { useContext, useEffect } from 'react'
import {FundContext} from "./List";
import {useSort, format_float} from "../helpers/common";
import CurrencyFormat from 'react-currency-format';

export default function Performance() {
  const funds = useContext(FundContext);
  const [state, setState, sortBy] = useSort({
    funds: []
  });

  useEffect(() => {
    setState({
      funds: funds.sort((first, second) => second.last_net_asset - first.last_net_asset)
    })
  }, [funds]);

  const sort = (name, numeric=false) => sortBy('funds', name, numeric);

  return (
    <>
      <div className="funds-sticky-bottom-table-header-filters">
        <div className="flex flex-center mxn2 py2 semi-bold text-green-vogue">
          <div onClick={() => sort("name")} className="funds-tab-2-col-1 px2">
            <p className="flex flex-center pl2">
              <span>Interval Fund</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("class_name")} className="funds-tab-2-col-4 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Class</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("category")} className="funds-tab-2-col-2 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Sector</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("ticker")} className="funds-tab-2-col-3 px2">
            <p className="flex flex-center flex-justify-center">
              <span>Ticker</span>
              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort('nav_share', true)} className="funds-tab-2-col-5 px2">
            <p className="flex flex-center">
              <span>NAV/Shr</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("total_return_gross")} className="funds-tab-2-col-6 px2">
            <p className="flex flex-center">
              <span>Total Return Gross</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("expense_ratio")} className="funds-tab-2-col-7 px2">
            <p className="flex flex-center">
              <span>Expense Ratio</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("total_return")} className="funds-tab-2-col-8 px2">
            <p className="flex flex-center">
              <span>Total Return</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>

          <div onClick={() => sort("current_yield_percentage")} className="funds-tab-2-col-9 px2">
            <p className="flex flex-center">
              <span>Current<br /> Yield</span>

              <i className="fa fa-sort ml1" />
            </p>
          </div>
        </div>
      </div>

      {state.funds.map((fund, idx) =>
        <a key={idx} href={`/funds/${fund.id}`}>
        <div className="border border-heather-o-40 center h5 mb2 rounded semi-bold text-slate-gray">
        <div className="flex flex-center mxn2">
          <div className="funds-tab-2-col-1 p2">
            <p className="left-align pl2">{fund.name}</p>
          </div>

          <div className="funds-tab-2-col-2 p2">
            <p>{fund.class_name}</p>
          </div>

          <div className="funds-tab-2-col-3 p2">
            <span className="bg-bahama-blue h6 inline-block lh-20 px1 text-upper text-white">{fund.category}</span>
          </div>

          <div className="funds-tab-2-col-4 p2">
            <p>{fund.ticker}</p>
          </div>

          <div className="funds-tab-2-col-5 p2">
            <p>{fund.nav_share == null? null : format_float(fund.nav_share)}</p>
          </div>

          <div className="funds-tab-2-col-6 p2">
            <p>
              {fund.total_return_gross && <CurrencyFormat
                thousandSeparator={true}
                prefix={'$'}
                value={format_float(fund.total_return_gross)}
                displayType={'text'}
              />}
            </p>
          </div>

          <div className="funds-tab-2-col-7 p2">
            <p>
              {fund.expense_ratio && <CurrencyFormat
                thousandSeparator={true}
                suffix={'%'}
                value={format_float(fund.expense_ratio)}
                displayType={'text'}
              />}
            </p>
          </div>

          <div className="funds-tab-2-col-8 p2">
            <p>
              {fund.total_return && <CurrencyFormat
                thousandSeparator={true}
                prefix={'$'}
                value={format_float(fund.total_return)}
                displayType={'text'}
              />}
            </p>
          </div>

          <div className="funds-tab-2-col-9 p2">
            <p>
              {fund.current_yield_percentage && <CurrencyFormat
                thousandSeparator={true}
                suffix={'%'}
                value={format_float(fund.current_yield_percentage)}
                displayType={'text'}
              />}
            </p>
          </div>
        </div>
      </div>
      </a>
      )}
    </>
  )
}
