import React, { useState, useEffect } from 'react'
import Article from './Article'
import InfiniteScroll from 'react-infinite-scroller'
// import {CSRF_TOKEN, Request} from "./helpers/request";
import {useFormState} from "./helpers/common";
import axios from "axios/index";

export default function Articles({
  infinite_scroll,
  refresh_button,
  endpoint,
  articles,
  params = {}
}) {
  const [hasMore, setHasMore] = useState(true);
  const [state, setState] = useFormState({
    currentPage: 2,
    currentArticles: [],
    newArticles: []
  });

  useEffect(() => {
    const interval = setInterval(() => {
          getArticles().then(articles => {
            const articlesIds = [...state.currentArticles.map(article => article.id ), ...articles.map(article => article.id)];
            const newArticles = articles.filter(article => !articlesIds.includes(article.id) );

            setState({ newArticles, currentArticles: [...newArticles, ...state.currentArticles]})
          });
    }, 180000);
    return () => clearInterval(interval);
  }, [state]);

  const loadMore = async() => {
    if (!infinite_scroll) return;

    if (hasMore) {
      let articles = await getArticles(state.currentPage);

      if (!articles.length || !infinite_scroll) {
        setHasMore(false);
      }

      setState({
        currentPage: state.currentPage + 1,
        currentArticles: [...state.currentArticles, ...articles]
      });
      }
  };

  const getArticles = (page) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'GET' ,
        url: `${endpoint}?page=${page}&${params.trending ? 'trending=true' : ''}&${params.category ? `category=${params.category}` : ''}`,
      }).then(response => resolve(response.data));
    })
  };

  if (!articles) return <p>There aren't any articles yet.</p>

  return (
    <div>
      <div className="border-bottom border-heather-o-40 flex flex-justify pb2 sm-block">
        <h1 className="box-title">Latest On Intervalfunds</h1>
      </div>

      {articles.map((article, index) => (<Article article={article} key={index} />))}

      {infinite_scroll && <InfiniteScroll
        pageStart={2}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<div className="loader" key={0}>Loading ...</div>}
        threshold={0}
      >
        {state.currentArticles.map((article, index) => (<Article article={article} key={index} />))}
      </InfiniteScroll>}

    </div>
  )
}
